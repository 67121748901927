import React, { useState } from 'react';
import { Form, Input, Button, Radio, Typography, Collapse, Card, Modal } from 'antd';
import { PhoneOutlined, UserOutlined, GlobalOutlined } from '@ant-design/icons';
import 'antd/dist/reset.css';
import axios from 'axios';

const { Title, Text } = Typography;
const { Panel } = Collapse;

const JobForm = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onFinish = async (values) => {
    try {
      // Replace YOUR_BOT_TOKEN and YOUR_CHAT_ID with your bot's token and your chat ID
      const botToken = '7525625851:AAGInjGREmZpj7C2taGKPHoFysIRN959FEU';
      const chatId = '-1002480041537';
      const message = `
        📋 @st_offi:

        Name: ${values.name}
        Phone: ${values.phone}
        Passport Valid: ${values.passport === 'yes' ? 'Yes' : 'No'}
      `;

      // Send the message to your Telegram bot
      await axios.post(`https://api.telegram.org/bot${botToken}/sendMessage`, {
        chat_id: chatId,
        text: message,
      });

      // Show the thank you modal
      setIsModalVisible(true);
    } catch (error) {
      console.error('Error sending form data to Telegram:', error);
      alert('Failed to send form data to Telegram');
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', backgroundColor: '#e6f7ff' }}>
      <div style={{ width: '100%', maxWidth: 600, borderRadius: '8px', overflow: 'hidden' }}>
        {/* Верхняя часть с текстом и фоном */}
        <div style={{ 
          padding: '24px', 
          backgroundColor: '#ffffff', 
          borderRadius: '8px 8px 0 0',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
          position: 'relative'
        }}>
          <div style={{
            position: 'absolute',
            top: '-10px',
            left: '80%',
            transform: 'translateX(-50%)',
            width: '100px',
            height: '100px',
            backgroundColor: '#003399',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
           marginTop: '40px'
          }}>
            <GlobalOutlined style={{ color: '#ffffff', fontSize: '70px'  }} />
          </div>
          <Title level={4} style={{ marginBottom: '16px', textAlign: 'left' , marginTop: '20px' }}>Работа в ЕС</Title>
          <Text style={{ display: 'block', marginBottom: '16px', textAlign: 'left' }}>Ответьте на вопросы и <br /> получите работу</Text>
          <div style={{ marginBottom: '35px', textAlign: 'left' }}>
            <Text type="secondary">Зп от 3000 Евро</Text>
          </div>
        </div>
        
        {/* Основная часть с формой */}
        <Card
          style={{
            marginTop: '-40px', // Adjust this value to overlap the white background area
            borderRadius: '0 0 8px 8px',
            padding: '24px',
            backgroundColor: '#ffffff',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
            transition: 'transform 0.3s, box-shadow 0.3s',
          }}
          bordered={false}
        >
          <Form
            name="job_form"
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="name"
              label="Введите свои контактные данные"
              rules={[{ required: true, message: 'Пожалуйста, введите ваше имя' }]}
            >
              <Input
                placeholder="Введите имя"
                prefix={<UserOutlined />}
              />
            </Form.Item>

            <Form.Item
              name="phone"
              rules={[{ required: true, message: 'Пожалуйста, введите ваш номер телефона' }]}
            >
              <Input
                placeholder="+ (___) ___-____"
                prefix={<PhoneOutlined />}
              />
            </Form.Item>

            <Form.Item
              name="passport"
              label="У Вас действителен загран?"
              rules={[{ required: true, message: 'Пожалуйста, выберите вариант' }]}
            >
              <Radio.Group>
                <Radio value="yes">Да</Radio>
                <Radio value="no">Нет</Radio>
              </Radio.Group>
            </Form.Item>

            {/* Дополнительная информация по вакансии */}
            <Collapse style={{ marginBottom: '20px' }}>
              <Panel header="Дополнительная информация о вакансии" key="1">
                <div style={{ padding: '10px', fontSize: '14px', lineHeight: '1.6' }}>
                  <Title level={5}>Ищем себе в семью личного водителя</Title>
                  <p><strong>Основные обязанности:</strong></p>
                  <ul>
                    <li>Возить детей в школу (детям 11 и 14 лет)</li>
                    <li>Доставлять главу семьи на работу</li>
                    <li>Сопровождать покупки</li>
                  </ul>
                  <p><strong>Условия:</strong></p>
                  <ul>
                    <li>Предоставляем жилье и питание</li>
                    <li>Покрываем другие расходы</li>
                    <li>Выплаты дважды в месяц</li>
                  </ul>
                  <p><strong>Локация:</strong> Берлин</p>
                  <p><strong>Зарплата:</strong> от 2600 Евро + бонусы и премии</p>
                  <p><strong>График:</strong> 5/2</p>
                </div>
              </Panel>
            </Collapse>

            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Отправить
              </Button>
            </Form.Item>

            <Text type="secondary" style={{ fontSize: '12px', textAlign: 'center' }}>
              Нажимая кнопку «Отправить», вы даете свое согласие на обработку персональных данных.
            </Text>
          </Form>
        </Card>
      </div>

      {/* Thank You Modal */}
      <Modal
        title="Спасибо!"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleOk}
        okText="ОК"
        cancelButtonProps={{ style: { display: 'none' } }} // Hide the cancel button
      >
        <p>Ваша заявка успешно отправлена. Мы свяжемся с вами в ближайшее время.</p>
      </Modal>
    </div>
  );
};

const App = () => {
  return (
    <div style={{ padding: '', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', backgroundColor: '#e6f7ff' }}>
      <JobForm />
    </div>
  );
};

export default App;
